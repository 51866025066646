import { Form } from '@remix-run/react'
import { Icon } from '~/components/ui/icons/icon.tsx'
import {
  Dialog,
  DialogDescription,
  DialogHeader,
} from '~/components/ui/dialog.tsx'
import { Button, ButtonTrigger } from '~/components/ui/inputs/button/Button.tsx'
import { Spacer } from '~/components/ui/spacer.tsx'
import { ActionTypeEnum } from 'types/enums.ts'
import { type ReactNode, useRef, useState } from 'react'
import { cn } from '~/utils/misc.tsx'

export const ActionDelete = ({
  actionUrl,
  id,
  description,
  trigger,
  actionType = ActionTypeEnum.delete,
  triggerClassName,
  dialogClassName,
}: {
  triggerClassName?: string
  actionUrl?: string
  id?: string | number
  description: string
  trigger?: ReactNode
  actionType?: string
  dialogClassName?: string
}) => {
  const [open, setOpen] = useState(false)
  const formRef = useRef<any>(null)
  const handleDelete = () => {
    formRef.current.submit()
    setOpen(false)
  }
  return (
    <>
      <div className="flex w-full justify-center" onClick={() => setOpen(true)}>
        {trigger ? (
          trigger
        ) : (
          <ButtonTrigger
            variant={'ghost'}
            size={'xs-circle'}
            className={triggerClassName}
          >
            <Icon name="trash" className="cursor-pointer text-red-500" />
          </ButtonTrigger>
        )}
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className={cn('sm:max-w-[425px]', dialogClassName)}
        containerClassName="p-0"
      >
        <DialogHeader variant="separator-bottom">Confirmar acción</DialogHeader>
        <Spacer size="3xs" />
        <div className=" px-4">
          <DialogDescription className="text-center">
            {description}
          </DialogDescription>
          <Spacer size="3xs" />
          <div className="flex w-full justify-end gap-4">
            <Button
              onClick={() => setOpen(false)}
              variant={'cancel'}
              size={'sm'}
            >
              Cancelar
            </Button>
            <Form ref={formRef} method="post" action={actionUrl}>
              <input name="type" type="hidden" value={actionType} />
              <input type="hidden" name="id" value={id} />
              <Button size={'sm'} type="submit" onClick={handleDelete}>
                Eliminar
              </Button>
            </Form>
          </div>
        </div>
        <Spacer size="4xs" />
      </Dialog>
    </>
  )
}
